import React, { Fragment } from "react"
import { Link } from "gatsby"
import Media from "react-media"
import Image from "./imageComponent.js"

import "animate.css"

import * as homeStyles from "../pages/index.module.css"

export default class HeroSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = { windowWidth: null, displayWideHero: false }
  }

  componentDidMount() {
    let width = window.innerWidth
    this.setState({ windowWidth: width, displayWideHero: true })

    let dataText = [
      "window tinting",
      "paint correction",
      "ceramic coating",
      "detailing",
      "vinyl wrapping",
      "paint protection film (PPF)",
      "paintwork enhancement",
      "headlight restoration",
      "headlight protection",
    ]

    function typeWriter(text, i, fnCallback) {
      const heroDOM = document.getElementById(homeStyles.animatedServices)
      if (heroDOM) {
        if (i < text.length) {
          heroDOM.innerHTML =
            text.substring(0, i + 1) + '<span aria-hidden="true"></span>'

          setTimeout(function () {
            typeWriter(text, i + 1, fnCallback)
          }, 100)
        } else if (typeof fnCallback == "function") {
          setTimeout(fnCallback, 700)
        }
      }
    }

    function StartTextAnimation(i) {
      if (typeof dataText[i] == "undefined") {
        StartTextAnimation(0)
      } else {
        if (i < dataText[i].length) {
          typeWriter(dataText[i], 0, function () {
            StartTextAnimation(i + 1)
          })
        }
      }
    }
    StartTextAnimation(0)
  }

  render() {
    return (
      <div className={homeStyles.heroContainer}>
        <div className={homeStyles.heroText}>
          <span>Multi-industry</span>
          <h1>
            <Link to="/services/" id={homeStyles.animatedServices}>
              Window tinting
            </Link>
          </h1>
          <span>specialists in</span>
          <span>Hampshire</span>
        </div>
        <Link to="/contact/">
          <button className={homeStyles.heroBtn + " " + "buttonStyle1"}>
            GET A FREE QUOTE
          </button>
        </Link>
        <div id={homeStyles.heroImgContainer}>
          <div id={homeStyles.heroImgOverlay}></div>
          {this.state.displayWideHero && (
            <Media
              queries={{
                small: "(max-width: 599px)",
                medium: "(min-width: 600px)",
              }}
            >
              {matches => (
                <Fragment>
                  {matches.small && (
                    <Image
                      imgClass={homeStyles.heroImg}
                      fileName="hero-mobile.jpg"
                      animated={false}
                    />
                  )}
                  {matches.medium && (
                    <Image
                      imgClass={homeStyles.heroWideImg}
                      fileName="hero-wide.jpg"
                      animated={false}
                    />
                  )}
                </Fragment>
              )}
            </Media>
          )}
        </div>
      </div>
    )
  }
}
