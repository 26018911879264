// extracted by mini-css-extract-plugin
export var animatedServices = "index-module--animatedServices--XqHFe";
export var heroBtn = "index-module--heroBtn--mKMml";
export var heroContainer = "index-module--heroContainer--S8D44";
export var heroImg = "index-module--heroImg--3SrPH";
export var heroImgContainer = "index-module--heroImgContainer--0tp9o";
export var heroImgOverlay = "index-module--heroImgOverlay--dP7NR";
export var heroText = "index-module--heroText--dARcD";
export var heroWideImg = "index-module--heroWideImg--jK4Vu";
export var projectCard = "index-module--projectCard--VITj1";
export var projectDescription = "index-module--projectDescription--exfOZ";
export var projectFilterText = "index-module--projectFilterText--xMCM+";
export var projectFilterTextAutomotive = "index-module--projectFilterTextAutomotive--cNPYW";
export var projectFiltersContainer = "index-module--projectFiltersContainer--2NmdB";
export var projectImg = "index-module--projectImg--Lbn5h";
export var projectTitle = "index-module--projectTitle--1spt1";
export var projectsContainer = "index-module--projectsContainer--zJ3BD";
export var projectsGrid = "index-module--projectsGrid---IJ9m";
export var reviewCard = "index-module--reviewCard--dD2KF";
export var reviewText = "index-module--reviewText--huQjh";
export var reviewsGrid = "index-module--reviewsGrid--AMGmU";
export var sectionContainer = "index-module--sectionContainer--HwYlD";