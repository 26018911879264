// extracted by mini-css-extract-plugin
export var colorImg = "waitingSection-module--colorImg--WT09j";
export var discolorImg = "waitingSection-module--discolorImg--lZxiu";
export var slidedown = "waitingSection-module--slidedown--LZyJN";
export var slideup = "waitingSection-module--slideup--Izl9I";
export var waitingBtn = "waitingSection-module--waitingBtn--bnzFr";
export var waitingBtnEffect = "waitingSection-module--waitingBtnEffect--fbauW";
export var waitingBtnImg = "waitingSection-module--waitingBtnImg--brsOS";
export var waitingBtnText = "waitingSection-module--waitingBtnText--TWnHz";
export var waitingBtnTextBG = "waitingSection-module--waitingBtnTextBG--gvX06";
export var waitingBtnTitle = "waitingSection-module--waitingBtnTitle--kybc+";
export var waitingGrid = "waitingSection-module--waitingGrid--JAHHM";